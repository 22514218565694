import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type HybridState = {
    deviceId: string
    mobileAppVerison: string
    deviceNotificationPermissionStatus: string
    deviceNotificationPermissionSnoozedUntil: number | null
    appleHealthAuthStatus: { permission: string; value: string }[] | null
    appleHealthAuthStatusError: boolean
    appleHealthStepData: any
    appleHealthSleepData: any
    appleHealthPromptComplete: boolean
    appleHealthPromptError: boolean
}

const initialState: HybridState = {
    deviceId: null,
    mobileAppVerison: null,
    deviceNotificationPermissionStatus: null,
    deviceNotificationPermissionSnoozedUntil: null,
    appleHealthAuthStatus: null,
    appleHealthAuthStatusError: false,
    appleHealthPromptComplete: null,
    appleHealthPromptError: false,
    appleHealthStepData: null,
    appleHealthSleepData: null
}

export const hybridSlice = createSlice({
    name: 'hybrid',
    initialState,
    reducers: {
        setDeviceId: (state, { payload }: PayloadAction<string>) => {
            state.deviceId = payload
        },
        setMobileAppVersion: (state, { payload }: PayloadAction<string>) => {
            state.deviceId = payload
        },
        setAppleHealthPromptComplete: (
            state,
            { payload }: PayloadAction<boolean>
        ) => {
            state.appleHealthPromptComplete = payload
        },
        setAppleHealthPromptError: (
            state,
            { payload }: PayloadAction<boolean>
        ) => {
            state.appleHealthPromptError = payload
        },
        setAppleHealthAuthStatus: (
            state,
            { payload }: PayloadAction<HybridState['appleHealthAuthStatus']>
        ) => {
            state.appleHealthAuthStatus = payload
        },
        setAppleHealthAuthStatusError: (
            state,
            {
                payload
            }: PayloadAction<HybridState['appleHealthAuthStatusError']>
        ) => {
            state.appleHealthAuthStatusError = payload
        },
        setAppleHealthStepData: (state, { payload }: PayloadAction<any>) => {
            state.appleHealthStepData = payload
        },
        setAppleHealthSleepData: (state, { payload }: PayloadAction<any>) => {
            state.appleHealthSleepData = payload
        },
        setDeviceNotificationPermissionStatus: (
            state,
            { payload }: PayloadAction<string>
        ) => {
            state.deviceNotificationPermissionStatus = payload
        },
        setDeviceNotificationPermissionSnoozedUntil: (
            state,
            { payload }: PayloadAction<number>
        ) => {
            state.deviceNotificationPermissionSnoozedUntil = payload
        }
    }
})

export const {
    setDeviceId,
    setMobileAppVersion,
    setDeviceNotificationPermissionStatus,
    setDeviceNotificationPermissionSnoozedUntil,
    setAppleHealthAuthStatus,
    setAppleHealthAuthStatusError,
    setAppleHealthStepData,
    setAppleHealthSleepData,
    setAppleHealthPromptComplete,
    setAppleHealthPromptError
} = hybridSlice.actions

export default hybridSlice.reducer
