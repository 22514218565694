import { navigateToUrl } from 'single-spa'
import {
    HybridEventType,
    HybridMessage,
    isRunningInWebview,
    postMessageToWebview
} from '../../mobile-communication'
import { captureException } from '../../utils'
import { getUserLocaleTimezone } from '../../utils/Profile'
import { useEffect } from 'react'
import store, {
    setDeviceNotificationPermissionStatus,
    setDeviceId,
    setLocale,
    setTimezone,
    setMobileAppVersion,
    setAppleHealthAuthStatus,
    setAppleHealthAuthStatusError,
    setAppleHealthStepData,
    setAppleHealthPromptComplete,
    setAppleHealthPromptError,
    setAppleHealthSleepData
} from '../../store'
import { savePlayerId } from '../../utils/Notifications'

const windowObj = window as any

const hybridFunctions = {
    HANDSHAKE: (parsedMessage?: HybridMessage) => {
        postMessageToWebview({
            type: HybridEventType.HANDSHAKE,
            value: `Hello ${parsedMessage.value}, this is Web!`
        })
    },
    SEND_USER_ID: (parsedMessage?: HybridMessage) => {
        postMessageToWebview({
            type: HybridEventType.SEND_USER_ID,
            value: store.getState().user.userId
        })
    },
    // Todo: eventually refactor to decouple from redux state
    SEND_WEB_STATE: (parsedMessage: HybridMessage) => {
        if (parsedMessage.value === 'settings') {
            const currentState = store.getState().settings
            //check if settings state matches db values
            getUserLocaleTimezone().then((dbValue) => {
                if (
                    dbValue.locale !== currentState.locale ||
                    dbValue.timezone !== currentState.timezone
                ) {
                    //if not, update the state with db values
                    store.dispatch(setLocale(dbValue.locale))
                    store.dispatch(setTimezone(dbValue.timezone))
                    //then send updated values to mobile
                    postMessageToWebview({
                        type: HybridEventType.SEND_WEB_STATE,
                        value: {
                            ...store.getState().settings,
                            locale: dbValue.locale,
                            timezone: dbValue.timezone
                        }
                    })
                    //reload the web app so that correct translations show
                    window.location.reload()
                } else {
                    //if values match, send them along to mobile as normal
                    postMessageToWebview({
                        type: HybridEventType.SEND_WEB_STATE,
                        value: {
                            ...store.getState().settings
                        }
                    })
                }
            })
        } else {
            const stateSlice =
                store.getState()[parsedMessage.value] || store.getState()
            postMessageToWebview({
                type: HybridEventType.SEND_WEB_STATE,
                value: {
                    ...stateSlice
                }
            })
        }
    },
    REQUEST_DEVICE_ID: (parsedMessage: HybridMessage) => {
        parsedMessage.value && store.dispatch(setDeviceId(parsedMessage.value))
    },
    REQUEST_MOBILE_APP_VERSION: (parsedMessage: HybridMessage) => {
        parsedMessage.value &&
            store.dispatch(setMobileAppVersion(parsedMessage.value))
    },
    NAVIGATE: (parsedMessage: HybridMessage) => {
        parsedMessage.value && navigateToUrl(parsedMessage.value)
    },
    REQUEST_PUSH_NOTIFICATION_PERMISSION_PROMPT: (
        parsedMessage: HybridMessage
    ) => {
        if (
            parsedMessage?.value?.status === 'authorized' &&
            parsedMessage?.value?.oneSignalPlayerId
        ) {
            savePlayerId(parsedMessage.value.oneSignalPlayerId)
            window.analytics?.track('today:pushNotifications_nativeAllowed')
        } else if (parsedMessage?.value?.status === 'denied') {
            window.analytics?.track('today:pushNotifications_nativeDenied')
        }
        parsedMessage?.value?.status &&
            store.dispatch(
                setDeviceNotificationPermissionStatus(
                    parsedMessage.value.status
                )
            )
    },
    REQUEST_PUSH_NOTIFICATION_PERMISSION_STATUS: async (
        parsedMessage: HybridMessage
    ) => {
        //save the player id if notification status has changed
        //from denied to allowed from native device settings
        if (
            parsedMessage?.value?.status === 'authorized' &&
            store.getState()?.hybrid?.deviceNotificationPermissionStatus ===
                'denied'
        ) {
            parsedMessage?.value?.oneSignalPlayerId &&
                savePlayerId(parsedMessage.value.oneSignalPlayerId)
        }
        //save status to store
        parsedMessage?.value?.status &&
            store.dispatch(
                setDeviceNotificationPermissionStatus(
                    parsedMessage.value.status
                )
            )
    },
    REQUEST_APPLE_HEALTH_STATUS: (parsedMessage: HybridMessage) => {
        if (parsedMessage?.value?.statusError != null) {
            store.dispatch(setAppleHealthAuthStatus(null))
            store.dispatch(setAppleHealthAuthStatusError(true))
        } else if (parsedMessage?.value?.status) {
            store.dispatch(setAppleHealthAuthStatus(parsedMessage.value.status))
            store.dispatch(setAppleHealthAuthStatusError(false))
        }
    },
    REQUEST_APPLE_HEALTH_PROMPT: (parsedMessage: HybridMessage) => {
        parsedMessage?.value?.appleHealthPromptComplete != null &&
            store.dispatch(
                setAppleHealthPromptComplete(
                    parsedMessage.value.appleHealthPromptComplete
                )
            )
        parsedMessage?.value &&
            store.dispatch(
                setAppleHealthPromptError(
                    parsedMessage.value.appleHealthPromptError != null
                )
            )
    },
    REQUEST_APPLE_HEALTH_STEPS_DATA: (parsedMessage: HybridMessage) => {
        parsedMessage?.value?.steps &&
            store.dispatch(setAppleHealthStepData(parsedMessage.value.steps))
    },
    REQUEST_APPLE_HEALTH_SLEEP_DATA: (parsedMessage: HybridMessage) => {
        parsedMessage?.value?.sleep &&
            store.dispatch(setAppleHealthSleepData(parsedMessage.value.sleep))
    }
}

export const useHybridFunctions = () => {
    useEffect(() => {
        if (isRunningInWebview() && !windowObj.handleNativeHandshake) {
            windowObj.handleNativeHandshake = (
                message: string | HybridMessage
            ) => {
                // eslint-disable-next-line no-console
                console.log('RECEIVED MESSAGE FROM NATIVE: ', message)
                let parsedMessage = {} as HybridMessage
                try {
                    if (
                        typeof message === 'string' ||
                        message instanceof String
                    )
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        parsedMessage = JSON.parse(message)
                    else {
                        parsedMessage = message
                    }
                    hybridFunctions[parsedMessage.type] &&
                        hybridFunctions[parsedMessage.type](parsedMessage)
                } catch (error) {
                    captureException(error, {
                        message: 'Failed to recieve message from Mobile'
                    })
                }
            }
            postMessageToWebview({
                type: HybridEventType.WEB_BRIDGE_READY
            })
        }
    }, [])
}

export default useHybridFunctions
