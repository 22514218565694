import { ADD_DEVICE } from '../../graphql/mutations/notifications'
import { captureException, getApolloClient } from '../../utils'

export async function savePlayerId(oneSignalPlayerId: string) {
    try {
        const client = getApolloClient()
        await client.mutate({
            variables: {
                deviceId: oneSignalPlayerId
            },
            mutation: ADD_DEVICE
        })
    } catch (error) {
        captureException(error, {
            message: `Unable to save user's player id.`
        })
    }
}
