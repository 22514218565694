import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export enum NotificationType {
    ERROR,
    ACHIEVEMENT,
    PLANT_WATERED
}

export interface NotificationModel {
    type: NotificationType
    payload: any
    color: 'error' | 'success' | 'info' | 'warning'
    autohide: boolean
}

export interface NotificationState {
    notification: NotificationModel
    showConfetti: boolean
}

const initialState: NotificationState = {
    notification: undefined,
    showConfetti: true
}

export const notification = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        setNotification: (
            state,
            { payload }: PayloadAction<NotificationModel>
        ) => {
            state.notification = payload
        },
        setShowConfetti: (state, { payload }: PayloadAction<boolean>) => {
            state.showConfetti = payload
        }
    }
})

export const { setNotification, setShowConfetti } = notification.actions

export default notification.reducer
