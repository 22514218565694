import {
    createTheme,
    Direction,
    responsiveFontSizes,
    Theme,
    ThemeOptions
} from '@mui/material'
import merge from 'lodash/merge'
import { THEMES } from './constants'
import { darkShadows, lightShadows } from './shadows'
import { lazy, Suspense } from 'react'

const GTAmericaRg = `${process.env.CDN_ASSETS}/fonts/GT-America-LCG-desktop/GT-America-LCG-Standard-Regular.otf`
const GTAmericaMd = `${process.env.CDN_ASSETS}/fonts/GT-America-LCG-desktop/GT-America-LCG-Standard-Medium.otf`
const GTSuperDisplay = `${process.env.CDN_ASSETS}/fonts/GT-Super-Full-desktop/GT-Super-Display-Medium.otf`

function withSuspense(Component: any) {
    return (props?: any) => {
        return (
            <Suspense fallback={<div />}>
                <Component {...props} />
            </Suspense>
        )
    }
}

const CampBWellLogo = withSuspense(lazy(() => import('./images/CampBWellLogo')))
const DemoLogo = withSuspense(lazy(() => import('./images/DemoLogo')))
const ThriveLogoSingleLeaf = withSuspense(
    lazy(() => import('./images/ThriveLogoSingleLeaf'))
)
const ThriveLogoSingleLeafOnDark = withSuspense(
    lazy(() => import('./images/ThriveLogoSingleLeafOnDark'))
)

export {}

interface ThemeConfig {
    direction?: Direction
    responsiveFontSizes?: boolean
    roundedCorners?: boolean
    theme?: string
}
type TypeCardOverlay = string

interface GradientPaletteColor {
    light: string
    main: string
}

interface GradientPaletteColorOptions {
    light?: string
    main?: string
}

export interface ExtendedTheme extends Theme {
    Logo: () => JSX.Element
    gradient: GradientPaletteColor
    videoTextProtectionOverlay: TypeCardOverlay
}

interface ExtendedThemeOptions extends ThemeOptions {
    Logo: () => JSX.Element
    gradient?: GradientPaletteColorOptions
    videoTextProtectionOverlay?: string
}

export const customTypography = {
    stat1: {
        fontFamily: 'GT America Rg',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '8rem',
        lineHeight: 0.94,
        letterSpacing: '-.01rem'
    },
    stat2: {
        fontFamily: 'GT America Rg',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '5.6rem',
        lineHeight: 1.14,
        letterSpacing: '-0.005rem'
    },
    stat3: {
        fontFamily: 'GT America Md',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '3.2rem',
        lineHeight: 1.19,
        letterSpacing: '-0.01rem'
    },
    display1: {
        fontFamily: 'GT Super Display',
        fontFeatureSettings: "'pnum' on, 'lnum' on",
        fontWeight: 500,
        fontStyle: 'normal',
        fontSize: '9.6rem',
        lineHeight: 1.04,
        letterSpacing: '-0.0015rem',
        '@media (max-width:600px)': {
            fontSize: '6.7rem',
            lineHeight: 1.1
        }
    },
    display2: {
        fontFamily: 'GT Super Display',
        fontFeatureSettings: "'pnum' on, 'lnum' on",
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '4.8rem',
        lineHeight: 1.17,
        letterSpacing: '-0.005rem'
    },
    display3: {
        fontFamily: 'GT America Rg',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '3.2rem',
        lineHeight: 1.19,
        letterSpacing: '-0.01rem'
    },
    display4: {
        fontFamily: 'GT Super Display',
        fontFeatureSettings: "'pnum' on, 'lnum' on",
        fontWeight: 500,
        fontStyle: 'normal',
        fontSize: '3.2rem',
        lineHeight: 1.19,
        letterSpacing: '-0.0015rem',
        '@media (max-width:600px)': {
            fontWeight: 400,
            fontSize: '2.4rem',
            lineHeight: 1.25
        }
    },
    display5: {
        fontFamily: 'GT Super Display',
        fontFeatureSettings: "'pnum' on, 'lnum' on",
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '1.8rem',
        lineHeight: 1.33,
        letterSpacing: '-0.015rem'
    },
    buttonLarge: {
        fontFamily: 'GT America Md',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '1.8rem',
        lineHeight: 1.33,
        letterSpacing: '-0.0015rem'
    },
    buttonNormal: {
        fontFamily: 'GT America Md',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '1.6rem',
        lineHeight: 1.37,
        letterSpacing: '-0.0015rem'
    },
    buttonSmall: {
        fontFamily: 'GT America Md',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '1.2rem',
        lineHeight: 1.5,
        letterSpacing: '0.08em',
        textTransform: 'uppercase'
    },
    form: {
        fontFamily: 'GT America Rg',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '1.8rem',
        lineHeight: 1.33,
        letterSpacing: '-0.0015rem'
    },
    formBody: {
        fontFamily: 'GT America Rg',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '2.8rem',
        lineHeight: 1.29,
        letterSpacing: '-0.005rem'
    }
}

const baseOptions: ThemeOptions = {
    direction: 'ltr',
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                * {
                    box-sizing: border-box;
                }
                html {
                    MozOsxFontSmoothing: grayscale;
                    WebkitFontSmoothing: antialiased;
                    height: 100%;
                    width: 100%;
                }
                body {
                    height: 100%;
                }
                #root {
                    height: 100%;
                }
                #nprogress .bar {
                    zIndex: 2000 !important;
                }
               
                @font-face {
                    font-family: GT Super Display;
                    src: local('GT Super Display'), local('GTSuperDisplay'), url(${GTSuperDisplay}) format('woff2');
                    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
                }
                @font-face {
                    font-family: GT America Rg;
                    src: local('GT America Rg'), local('GTAmericaRg'), url(${GTAmericaRg}) format('woff2');
                    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
                }
                @font-face {
                    font-family: GT America Md;
                    src: local('GT America Md'), local('GTAmericaMd'), url(${GTAmericaMd}) format('woff2');
                    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
                }
            `
        },
        MuiGrid: {
            defaultProps: {
                spacing: {
                    xs: 2,
                    sm: 2,
                    md: 4,
                    lg: 4,
                    xl: 4
                },
                columns: 12
            }
        },
        MuiAvatar: {
            styleOverrides: {
                fallback: {
                    height: '75%',
                    width: '75%'
                }
            }
        },
        MuiButton: {
            defaultProps: {
                onKeyDown: (event) => {
                    //this is in place to suppress tab closing dialogs and popovers we want to navigate through once opened
                    if (event.key === 'Tab') {
                        event.stopPropagation()
                    }
                }
            },
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    borderWidth: '2px !important',
                    ':focus-visible': {
                        border: '2px solid blue'
                    }
                }
            }
        },
        MuiButtonBase: {
            defaultProps: {
                onKeyDown: (event) => {
                    //this is in place to suppress tab closing dialogs and popovers we want to navigate through once opened
                    if (event.key === 'Tab') {
                        event.stopPropagation()
                    }
                }
            },
            styleOverrides: {
                root: {
                    ':focus-visible': {
                        border: '2px solid blue'
                    }
                }
            }
        },
        MuiCardHeader: {
            defaultProps: {
                titleTypographyProps: {
                    variant: 'h5'
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    '.MuiTypography-root': {
                        color: 'inherit'
                    }
                }
            }
        },
        MuiTab: {
            defaultProps: {
                tabIndex: 0 //this is set for a11y keyboard navigation compliance, do not override
            }
        },
        MuiMenuItem: {
            defaultProps: {
                tabIndex: 0 //this is set for a11y keyboard navigation compliance, do not override
            }
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    borderRadius: 3,
                    overflow: 'hidden'
                }
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    minWidth: 'auto',
                    marginRight: '16px'
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    overline: {
                        color: (props) => props.theme.palette.text.secondary
                    }
                }
            }
        }
    },
    typography: {
        fontFamily: "'GT Super Display', 'GT America Md', 'GT America Rg'",
        h1: {
            fontFamily: 'GT Super Display',
            fontFeatureSettings: "'pnum' on, 'lnum' on",
            fontWeight: 400,
            fontStyle: 'normal',
            fontSize: '4.8rem',
            lineHeight: 1.08,
            letterSpacing: '-.15rem',
            '@media (max-width:600px)': {
                fontSize: '3.2rem',
                lineHeight: 1.25,
                letterSpacing: '-0.005rem'
            }
        },
        h2: {
            fontFamily: 'GT America Md',
            fontWeight: 400,
            fontStyle: 'normal',
            fontSize: '3.2rem',
            lineHeight: 1.19,
            letterSpacing: '-0.01rem',
            '@media (max-width:600px)': {
                fontSize: '2.8rem',
                lineHeight: 1.21
            }
        },
        h3: {
            fontFamily: 'GT America Md',
            fontWeight: 400,
            fontStyle: 'normal',
            fontSize: '2.4rem',
            lineHeight: 1.17,
            letterSpacing: '-0.0015rem'
        },
        h4: {
            fontFamily: 'GT America Md',
            fontWeight: 400,
            fontStyle: 'normal',
            fontSize: '2rem',
            lineHeight: 1.2,
            letterSpacing: '-0.0015rem'
        },
        h5: {
            fontFamily: 'GT America Md',
            fontWeight: 500,
            fontStyle: 'normal',
            fontSize: '1.8rem',
            lineHeight: 1.33,
            letterSpacing: '-0.0015rem',
            '@media (max-width:600px)': {
                fontSize: '1.6rem',
                lineHeight: 1.37
            }
        },
        h6: {
            fontFamily: 'GT America Md',
            fontWeight: 400,
            fontStyle: 'normal',
            fontSize: '1.4rem',
            lineHeight: 1.29,
            letterSpacing: '0.01rem',
            '@media (max-width:600px)': {
                fontSize: '1.2rem',
                lineHeight: 1.17
            }
        },
        caption: {
            fontFamily: 'GT America Rg',
            fontWeight: 400,
            fontStyle: 'normal',
            fontSize: '1.4rem',
            lineHeight: 1.33,
            letterSpacing: '-0.0015rem',
            '@media (max-width:600px)': {
                lineHeight: 1.5
            }
        },
        overline: {
            fontFamily: 'GT America Md',
            fontWeight: 'normal',
            fontSize: '1.4rem',
            lineHeight: 1.43,
            letterSpacing: '.12rem',
            textTransform: 'uppercase',
            '@media (max-width:600px)': {
                fontSize: '1.2rem',
                lineHeight: 1.5
            }
        },
        body1: {
            fontFamily: 'GT America Rg',
            fontWeight: 400,
            fontStyle: 'normal',
            fontSize: '1.6rem',
            lineHeight: 1.37,
            letterSpacing: '-0.0015rem'
        },
        body2: {
            fontFamily: 'GT America Rg',
            fontWeight: 400,
            fontStyle: 'normal',
            fontSize: '1.4rem',
            lineHeight: 1.43,
            letterSpacing: '-0.0015rem'
        }
    }
}

const themesOptions: Record<string, ExtendedThemeOptions> = {
    [THEMES.THRIVE]: {
        Logo: ThriveLogoSingleLeaf,
        components: {
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        '&::placeholder': {
                            opacity: 0.86,
                            color: '#42526e'
                        }
                    }
                }
            }
        },
        palette: {
            primary: {
                main: '#1E0056',
                dark: '#16003F',
                light: '#4D1AAD',
                contrastText: '#FFFFFF'
            },
            secondary: {
                main: '#ECEBF7',
                dark: '#D4D2EE',
                light: '#efeff8',
                contrastText: '#16003F'
            },
            mode: 'light',
            background: {
                default: '#f4f5f7',
                paper: '#ffffff'
            },
            text: {
                primary: '#172235',
                secondary: '#14808E',
                disabled: '#6A717D'
            },
            info: {
                main: '#ffffff',
                dark: '#F7F7F8',
                light: '#ffffff',
                contrastText: '#1E0056'
            },
            error: {
                main: '#A3292F',
                dark: '#840E13',
                light: '#FFF4F5',
                contrastText: '#ffffff'
            },
            warning: {
                main: '#F6C344',
                dark: '#663C00',
                light: '#FBF9F2',
                contrastText: '#ffffff'
            },
            success: {
                main: '#3FA3B0',
                dark: '#035A66',
                light: '#A7DBD5',
                contrastText: '#ffffff'
            },
            grey: {
                50: '#FBFBFB',
                100: '#F7F7F8',
                200: '#DBDDE0',
                300: '#BFC2C7',
                400: '#A2A6AE',
                500: '#868B95',
                600: '#868B95',
                700: '#4F5865',
                800: '#343E4E',
                900: '#172235'
            },
            action: {
                active: '#6b778c'
            }
        },
        gradient: {
            light: 'linear-gradient(128.32deg, #FDFDFD 3.91%, #EBF3F4 94.19%)',
            main: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 50%), linear-gradient(33.27deg, #EAE8FC 29.3%, rgba(241, 238, 233, 0.98) 83.39%);'
        },
        videoTextProtectionOverlay: '#000000BF',
        shadows: lightShadows,
        typography: {
            overline: {
                color: '#14808E'
            }
        }
    },
    [THEMES.DARK]: {
        Logo: ThriveLogoSingleLeafOnDark,
        components: {
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        borderBottom: '1px solid rgba(145, 158, 171, 0.24)'
                    }
                }
            }
        },
        palette: {
            primary: {
                main: '#4B3378',
                dark: '#1E0056',
                light: '#A599BB',
                contrastText: '#ffffff'
            },
            secondary: {
                main: '#5DB4BC',
                dark: '#145A6A',
                light: '#B0D5DE',
                contrastText: '#FFFFFF'
            },
            mode: 'dark',
            background: {
                default: '#171c24',
                paper: '#222b36'
            },
            divider: 'rgba(145, 158, 171, 0.24)',
            text: {
                primary: '#ffffff',
                secondary: '#adbcd6'
            },
            info: {
                main: '#5DB4BC',
                dark: '#145A6A',
                light: '#9DCCD7',
                contrastText: '#FFFFFF'
            },
            error: {
                main: '#C54160',
                dark: '#90253F',
                light: '#E793A2',
                contrastText: '#ffffff'
            },
            warning: {
                main: '#C7B4A7',
                dark: '#AA9587',
                light: '#E2D0C3',
                contrastText: '#000000de'
            },
            success: {
                main: '#5DB4BC',
                dark: '#145A6A',
                light: '#9DCCD7',
                contrastText: '#ffffff'
            },
            grey: {
                50: '#FBFBFB',
                100: '#F7F7F8',
                200: '#DBDDE0',
                300: '#BFC2C7',
                400: '#A2A6AE',
                500: '#868B95',
                600: '#868B95',
                700: '#4F5865',
                800: '#343E4E',
                900: '#172235'
            }
        },
        gradient: {
            light: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 50%), linear-gradient(33.27deg, #EAE8FC 29.3%, rgba(241, 238, 233, 0.98) 83.39%);',
            main: 'linear-gradient(128.32deg, #FDFDFD 3.91%, #EBF3F4 94.19%)'
        },
        videoTextProtectionOverlay: '#000000BF',
        shadows: darkShadows
    },
    [THEMES.NATURE]: {
        Logo: ThriveLogoSingleLeafOnDark,
        components: {
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        borderBottom: '1px solid rgba(145, 158, 171, 0.24)'
                    }
                }
            }
        },
        palette: {
            primary: {
                main: '#286930',
                contrastText: '#ffffff'
            },
            mode: 'dark',
            background: {
                default: '#1c2531',
                paper: '#293142'
            },
            divider: 'rgba(145, 158, 171, 0.24)',
            text: {
                primary: '#ffffff',
                secondary: '#8dfb9a'
            },
            info: {
                main: '#5DB4BC',
                dark: '#145A6A',
                light: '#9DCCD7',
                contrastText: '#FFFFFF'
            },
            error: {
                main: '#C54160',
                dark: '#90253F',
                light: '#E793A2',
                contrastText: '#ffffff'
            },
            warning: {
                main: '#C7B4A7',
                dark: '#AA9587',
                light: '#E2D0C3',
                contrastText: '#000000de'
            },
            success: {
                main: '#5DB4BC',
                dark: '#145A6A',
                light: '#9DCCD7',
                contrastText: '#ffffff'
            },
            grey: {
                50: '#FBFBFB',
                100: '#F7F7F8',
                200: '#DBDDE0',
                300: '#BFC2C7',
                400: '#A2A6AE',
                500: '#868B95',
                600: '#868B95',
                700: '#4F5865',
                800: '#343E4E',
                900: '#172235'
            }
        },
        gradient: {
            light: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 50%), linear-gradient(33.27deg, #EAE8FC 29.3%, rgba(241, 238, 233, 0.98) 83.39%);',
            main: 'linear-gradient(128.32deg, #FDFDFD 3.91%, #EBF3F4 94.19%)'
        },
        videoTextProtectionOverlay: '#000000BF',
        shadows: darkShadows
    },
    [THEMES.CAMPBWELL]: {
        Logo: CampBWellLogo,
        components: {
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        '&::placeholder': {
                            opacity: 0.86,
                            color: '#42526e'
                        }
                    }
                }
            }
        },
        palette: {
            primary: {
                main: '#016DCD',
                dark: '#014486',
                light: '#aacbff',
                contrastText: '#FFFFFF'
            },
            secondary: {
                main: '#F68757',
                contrastText: '#FFFFFF'
            },
            mode: 'light',
            action: {
                active: '#6b778c'
            },
            background: {
                default: '#f4f5f7',
                paper: '#ffffff'
            },
            text: {
                primary: '#253858',
                secondary: '#F68757',
                disabled: '#09274261'
            },
            info: {
                main: '#ffffff',
                dark: '#ffffff',
                light: '#ffffff',
                contrastText: '#016DCD'
            },
            error: {
                main: '#C54160',
                dark: '#90253F',
                light: '#E793A2',
                contrastText: '#ffffff'
            },
            warning: {
                main: '#C7B4A7',
                dark: '#AA9587',
                light: '#E2D0C3',
                contrastText: '#000000de'
            },
            success: {
                main: '#5DB4BC',
                dark: '#145A6A',
                light: '#9DCCD7',
                contrastText: '#ffffff'
            },
            grey: {
                50: '#FBFBFB',
                100: '#F7F7F8',
                200: '#DBDDE0',
                300: '#BFC2C7',
                400: '#A2A6AE',
                500: '#868B95',
                600: '#868B95',
                700: '#4F5865',
                800: '#343E4E',
                900: '#172235'
            }
        },
        gradient: {
            light: 'linear-gradient(128.32deg, #FDFDFD 3.91%, #EBF3F4 94.19%)',
            main: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 50%), linear-gradient(33.27deg, #EAE8FC 29.3%, rgba(241, 238, 233, 0.98) 83.39%);'
        },
        videoTextProtectionOverlay: '#000000BF',
        shadows: lightShadows
    },
    [THEMES.HIGH_CONTRAST]: {
        Logo: ThriveLogoSingleLeafOnDark,
        components: {
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        borderBottom: '1px solid rgba(145, 158, 171, 0.24)'
                    }
                }
            },
            MuiSvgIcon: {
                styleOverrides: {
                    colorSecondary: {
                        color: '#000',
                        backgroundColor: '#fff'
                    }
                }
            },
            MuiLink: {
                styleOverrides: {
                    root: {
                        color: '#ffff00',
                        backgroundColor: '#000',
                        // specificity to override Typography where needed
                        '& *[class*="MuiTypography-"]': {
                            color: '#ffff00',
                            backgroundColor: '#000'
                        }
                    }
                }
            },
            MuiChip: {
                defaultProps: {
                    color: 'primary'
                }
            },
            MuiAvatar: {
                styleOverrides: {
                    root: {
                        '& svg': {
                            color: '#000',
                            fillColor: '#000'
                        }
                    }
                }
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        // hard overrides as dictated by HCM
                        '& *[class*="selected"]': {
                            color: '#000',
                            backgroundColor: '#1aebff'
                        },
                        '& *[class*="disabled"]': {
                            color: '#3ff23f'
                        },
                        '& .MuiLink': {
                            '& *[class*="MuiTypography-"]': {
                                color: '#ffff00',
                                backgroundColor: '#000'
                            }
                        }
                    }
                }
            },
            MuiTabs: {
                styleOverrides: {
                    root: {
                        '& button': {
                            color: '#ffff00'
                        },
                        '& *[class*="selected"] > *': {
                            color: '#000'
                        },
                        '& *[class*="selected"] ': {
                            backgroundColor: '#1aebff'
                        },
                        disabled: {
                            color: '#3ff23f',
                            backgroundColor: '#000'
                        }
                    }
                }
            },
            MuiCard: {
                defaultProps: {
                    variant: 'outlined'
                }
            },
            MuiButton: {
                styleOverrides: {
                    text: {
                        border: '1px solid #fff'
                    },

                    containedSecondary: {
                        color: '#000',
                        fill: '#000',
                        backgroundColor: '#fff',
                        '&:hover': {
                            backgroundColor: 'rgba(255,255,255, 0.3)'
                        },
                        // selector war bc PulseSurveyDialogContent targets the state and not the prop
                        '&:disabled': {
                            backgroundColor: '#3ff23f !important',
                            text: '#000 !important'
                        },
                        '& path': {
                            fill: '#000'
                        }
                    },
                    root: {
                        '& *[class*="disabled"]': {
                            backgroundColor: '#3ff23f',
                            text: '#000'
                        },
                        '& *[class*="disabled"] > *': {
                            text: '#000'
                        },
                        '&.Mui-disabled': {
                            backgroundColor: '#3ff23f !important',
                            text: '#000 !important'
                        }
                    }
                }
            },
            MuiButtonBase: {
                styleOverrides: {
                    root: {
                        '&.MuiButton-root.Mui-disabled': {
                            backgroundColor: '#3ff23f',
                            color: '#000'
                        },
                        '&.MuiToggleButton-root.Mui-selected': {
                            color: '#000'
                        }
                    }
                }
            },
            MuiSwitch: {
                styleOverrides: {
                    switchBase: {
                        color: '#ff00ff'
                    }
                }
            }
        },
        palette: {
            primary: {
                main: '#fff',
                dark: '#fff',
                light: '#fff',
                contrastText: '#000'
            },
            secondary: {
                main: '#fff',
                dark: '#fff',
                light: '#fff',
                contrastText: '#000'
            },
            mode: 'dark',
            background: {
                default: '#000',
                paper: '#000'
            },
            divider: '#fff',
            text: {
                primary: '#fff',
                secondary: '#fff',
                disabled: '#3ff23f'
            },
            info: {
                main: '#fff',
                dark: '#fff',
                light: '#fff',
                contrastText: '#000'
            },
            error: {
                main: '#fff',
                dark: '#fff',
                light: '#fff',
                contrastText: '#000'
            },
            warning: {
                main: '#fff',
                dark: '#fff',
                light: '#fff',
                contrastText: '#000'
            },
            success: {
                main: '#fff',
                dark: '#fff',
                light: '#fff',
                contrastText: '#000'
            },
            grey: {
                50: '#000',
                100: '#000',
                200: '#000',
                300: '#000',
                400: '#000',
                500: '#fff',
                600: '#fff',
                700: '#fff',
                800: '#fff',
                900: '#fff'
            },
            action: {
                selected: '#1aebff',
                selectedOpacity: 1,
                focus: 'fuchsia'
            }
        },
        gradient: {
            main: '#000',
            light: '#000'
        },
        videoTextProtectionOverlay: '#000000BF',
        shadows: darkShadows,
        typography: {
            h1: { color: '#fff' },
            h2: { color: '#fff' },
            h3: { color: '#fff' }
        }
    },
    [THEMES.DEMO]: {
        Logo: DemoLogo,
        components: {
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        '&::placeholder': {
                            opacity: 0.86,
                            color: '#42526e'
                        }
                    }
                }
            }
        },
        palette: {
            primary: {
                main: '#2318B6',
                dark: '#18107f',
                light: '#4f46c4',
                contrastText: '#FFF'
            },
            secondary: {
                main: '#eaf5fe',
                dark: '#eaf5fe',
                light: '#eaf5fe',
                contrastText: '#4400A6'
            },
            mode: 'light',
            action: {
                active: '#6b778c'
            },
            background: {
                default: '#f4f5f7',
                paper: '#ffffff'
            },
            text: {
                primary: '#172235',
                secondary: '#343E4E',
                disabled: '#6A717D'
            },
            info: {
                main: '#ffffff',
                dark: '#ffffff',
                light: '#ffffff',
                contrastText: '#0d5daa'
            },
            error: {
                main: '#A3292F',
                dark: '#840E13',
                light: '#FFF4F5',
                contrastText: '#ffffff'
            },
            warning: {
                main: '#F6C344',
                dark: '#663C00',
                light: '#FBF9F2',
                contrastText: '#ffffff'
            },
            success: {
                main: '#70bf75',
                dark: '#386546',
                light: '#cdf0c4',
                contrastText: '#ffffff'
            },
            grey: {
                50: '#FBFBFB',
                100: '#F7F7F8',
                200: '#DBDDE0',
                300: '#BFC2C7',
                400: '#A2A6AE',
                500: '#868B95',
                600: '#868B95',
                700: '#4F5865',
                800: '#343E4E',
                900: '#172235'
            }
        },
        gradient: {
            light: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 50%), linear-gradient(33.27deg, #ebf6ff 29.3%, rgba(241, 238, 233, 0.98) 83.39%);',
            main: 'linear-gradient(128.32deg, #FDFDFD 3.91%, #EBF3F4 94.19%)'
        },
        videoTextProtectionOverlay: '#000000BF',
        shadows: lightShadows
    }
}

const createCustomTheme = (config: ThemeConfig = {}): ExtendedTheme => {
    let themeOptions = themesOptions[config.theme]
    if (!themeOptions) {
        console.warn(new Error(`The theme ${config.theme} is not valid`))
        themeOptions = themesOptions[THEMES.THRIVE]
    }
    let theme = createTheme(
        merge(
            {},
            baseOptions,
            themeOptions,
            {
                ...(config.roundedCorners && {
                    shape: {
                        borderRadius: 16
                    }
                })
            },
            {
                direction: config.direction
            }
        )
    ) as ExtendedTheme //casting is safe here as createTheme and responsiveFontSizes actually preserve additional properties
    if (config.responsiveFontSizes) {
        theme = responsiveFontSizes(theme) as ExtendedTheme
    }
    return theme
}
// Main exported theme shared for all the micro apps
export const applicationTheme = createCustomTheme({
    direction: 'ltr',
    responsiveFontSizes: false,
    roundedCorners: true,
    theme: THEMES.THRIVE
})

// Main exported theme shared for all the micro apps
export const highContrastTheme = createCustomTheme({
    direction: 'ltr',
    responsiveFontSizes: false,
    roundedCorners: true,
    theme: THEMES.HIGH_CONTRAST
})
export function getAllThemes(): Record<string, ExtendedTheme> {
    const themes = {}
    Object.values(THEMES).forEach((element) => {
        themes[element] = createCustomTheme({ theme: element })
    })

    return themes
}
