import { NotificationType, setNotification } from '../../store'
import { captureException } from '../Sentry'
import store from './../../store'

export interface NotificationError {
    title: string
    message: string
    error?: any
    color?: 'error' | 'success' | 'info' | 'warning'
}

export const notifyError = (notificationError: NotificationError) => {
    store.dispatch(
        setNotification({
            type: NotificationType.ERROR,
            payload: notificationError,
            color: notificationError.color || 'error',
            autohide: true
        })
    )
    captureException(new Error(), notificationError.error)
}
